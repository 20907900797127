import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, Typography } from "@mui/material";
import ProviderDetailsForm from "../../molecules/Providers/ProviderDetailsForm";
import ProviderAccountList from "../../molecules/Providers/ProviderAccountList";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import {
  addProviderFromAccount,
  getProviderDetail,
  updateProviderFromAccount,
} from "../../api/providers";

const initialValues = {
  provider: {
    legalName: "",
    code: "",
    taxId: "",
    providerOriginId: "",
    providerTypeId: "",
    regimeId: "",
    subregimeId: "",
    corporationTypeId: "",
    descripcion: "",
    website: "",
    creditDays: 0,
  },
  accounts: [{ contpaqiAccountNo: "", description: "", isDefault: true }],
};

const validationSchema = Yup.object().shape({
  provider: Yup.object().shape({
    legalName: Yup.string().required("El nombre legal es obligatorio"),
    code: Yup.string().required("El código es obligatorio"),
    taxId: Yup.string().required("El ID fiscal es obligatorio"),
    providerOriginId: Yup.number()
      .required("El Origen del proveedor es obligatorio")
      .integer(),
    providerTypeId: Yup.number().integer(),
    regimeId: Yup.number().integer().nullable(),
    subregimeId: Yup.number().integer().nullable(),
    corporationTypeId: Yup.number().nullable(),
    descripcion: Yup.string(),
    website: Yup.string().url("URL inválida"),
    creditDays: Yup.number()
      .required("Los días de crédito son obligatorios")
      .integer(),
  }),
  accounts: Yup.array().of(
    Yup.object().shape({
      contpaqiAccountNo: Yup.string().required(
        "La cuenta contable es requerida."
      ),
      description: Yup.string().required("La descripción es requerida."),
    })
  ),
});

const ProviderForm = ({ companyId, onSubmit, id }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getProviderDetail(id, companyId);
      const payload = {
        provider: res.data,
        accounts: res.data.accounts,
      };
      setFormData(payload);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    try {
      if (formMode == "insert") {
        let payload = values;
        payload.accounts = payload.accounts.map((e) => ({ ...e, companyId }));
        const submitForm = await addProviderFromAccount(payload);
        if (submitForm.status == 200) {
          openSnack("Proveedor guardado exitosamente", "success");
        }
      } else if (formMode == "edit") {
        let payload = values;
        payload.accounts = payload.accounts.map((e) => ({ ...e, companyId }));
        const submitForm = await updateProviderFromAccount(id, payload);
        if (submitForm.status == 200) {
          openSnack("Proveedor actualizado exitosamente", "success");
        }
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      initialValues={{ ...formData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form id={id}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography pb={2} pt={4} variant="h4">
                Datos Generales
              </Typography>
              <ProviderDetailsForm errors={errors} touched={touched} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" pb={2} pt={4}>
                Cuentas Contables
              </Typography>
              <ProviderAccountList
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                formMode={formMode}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderForm;
