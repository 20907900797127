import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import ShipmentTable from "../../organisms/Imports/ShipmentTable";
import ShipmentCalendar from "../../organisms/Imports/ShipmentCalendar";
import { useSnack } from "../../services/SnakbarProvider";
import { useCompany } from "../../contexts/CompanyContext";
import ShipmentReports from "../../organisms/Imports/ShipmentReports";
import CrudCatalogue from "../../organisms/Catalogues/CrudCatalogue";

export default function ShipmentScreen() {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompany } = useCompany();
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label={`Embarques ${selectedCompany.name}`} />
          <Tab label="Catálogos" />
          <Tab label="Reportes" />
        </Tabs>
      </Box>

      <LGTabPanel value={activeTab} index={0}>
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item md={12} xl={6} height={"600px"}>
            <ShipmentCalendar companyId={selectedCompany.id} />
          </Grid>
          <Grid item md={12} xl={6}>
            <ShipmentTable companyId={selectedCompany.id} />
          </Grid>
        </Grid>
      </LGTabPanel>

      <LGTabPanel value={activeTab} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Navieras / Aerolineas</Typography>
              <CrudCatalogue catalogueName="FOREIGN_TRANSPORT" />
              <Typography variant="h4">Forwarders</Typography>
              <CrudCatalogue catalogueName="FORWARDER" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Agentes Aduanales</Typography>
              <CrudCatalogue catalogueName="CUSTOMS_AGENT" />
              <Typography variant="h4">Tipos de Embarque</Typography>
              <CrudCatalogue catalogueName="SHIPMENT_TYPE" />
              <Typography variant="h4">Modos de Embarque</Typography>
              <CrudCatalogue catalogueName="SHIPMENT_MODE" />
            </Stack>
          </Grid>
        </Grid>
      </LGTabPanel>

      <LGTabPanel value={activeTab} index={2}>
        <ShipmentReports companyId={selectedCompany.id} />
      </LGTabPanel>
    </DashboardLayout>
  );
}
