import { Box } from "@mui/material";
import React from "react";
import PurchaseOrderFormV2 from "./PurchaseOrderFormV2";

export default function POUpdateForm({ onSubmit, id }) {
  return (
    <Box>
      <PurchaseOrderFormV2 onSubmit={onSubmit} id={id} />
    </Box>
  );
}
