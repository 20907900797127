import { api } from "..";

const endpoint = "/api/enterprise";

// COMPANIES
export const getCompanyList = () => {
  return api.get(`${endpoint}/companies`);
};

export const addCompany = (data) => {
  return api.post(`${endpoint}/companies`, data);
};

export const updateCompany = (id, data) => {
  return api.put(`${endpoint}/companies/${id}`, data);
};

export const deleteCompany = (id) => {
  return api.delete(`${endpoint}/companies/${id}`);
};

// PLANTS
export const getPlantList = (companyId) => {
  return api.get(`${endpoint}/plants`, { companyId });
};

export const addPlant = (data) => {
  return api.post(`${endpoint}/plants`, data);
};

export const updatePlant = (id, data) => {
  return api.put(`${endpoint}/plants/${id}`, data);
};

export const deletePlant = (id) => {
  return api.delete(`${endpoint}/plants/${id}`);
};
