import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import CrudCatalogue from "../../organisms/Catalogues/CrudCatalogue";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";

export default function AssetScreen() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} sx={{}}>
          <Tab label="Máqinas" />
          <Tab label="Flota" />
        </Tabs>
      </Box>

      <LGTabPanel value={value} index={0}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Máquinas</Typography>
          <CrudCatalogue catalogueName={"ASSET_MACHINE"} />
        </Stack>
      </LGTabPanel>

      <LGTabPanel value={value} index={1}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Flota de transporte</Typography>
          <CrudCatalogue catalogueName={"ASSET_TRANSPORT"} codeHeader="Placa" />
        </Stack>
      </LGTabPanel>
    </DashboardLayout>
  );
}
