import { api } from "..";

const endpoint = "/api/shipments";

// SHIPMENT
export const getShipmentList = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const getShipmentDetail = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addShipment = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateShipment = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteShipment = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

//FILES
export const getShipmentFileList = (queryParams) => {
  return api.get(`${endpoint}/files`, { params: queryParams });
};

export const downloadShipmentFile = (id) => {
  return api.get(`${endpoint}/files/${id}`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};

export const deleteShipmentFile = (id) => {
  return api.delete(`${endpoint}/files/${id}`);
};

export const uploadShipmentFiles = (data, file) => {
  const formData = new FormData();
  formData.append("metadata", JSON.stringify(data));
  formData.append("file", file, file.name);

  return api.post(`${endpoint}/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//EXPORT
export const exportShipments = (queryParams) => {
  return api.get(`${endpoint}/export`, { params: queryParams });
};
  