import React, { useState } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import dayjs from "dayjs";
import PurchaseOrderForm from "./PurchaseOrderForm";
import {
  deletePurchaseOrder,
  exportPO,
  getPurchaseOrderList,
} from "../../api/purchaseOrders";
import POExport from "../../molecules/PurchaseOrder/POExport";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import {
  currencyFormatterMXN,
  dateFormatter,
} from "../../services/valueFormatters";
import POUpdateForm from "./POUpdateForm";
import PurchaseOrderFormV2 from "./PurchaseOrderFormV2";

export default function PurchaseOrderTable({ companyId }) {
  const { openSnack } = useSnack();

  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "PO_SIN_APROBAR":
        return "warning";
      case "PO_APROBADA":
        return "success";
      case "PO_CANCELADA":
        return "error";
    }
  };

  const handleFileDownload = async (id) => {
    try {
      const response = await exportPO(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "ordencompra";

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(
        "No se pudo generar el PDF, verifica que el proveedor tenga contacto y dirección",
        "error"
      );
    }
  };

  return (
    <Box width={"100%"}>
      <LGModalCrud
        props={{
          actions: ["create", "update", "download", "delete", "detail"],
          data: { companyId },
          fetchFunction: getPurchaseOrderList,
          deleteFunction: deletePurchaseOrder,
          downloadFunction: handleFileDownload,
          detailMenu: [
            {
              label: "Duplicar",
              handler: () => {},
              header: "Duplicar Órden de compra",
              body: <PurchaseOrderFormV2 duplicate={true} />,
            },
          ],
          columns: [
            {
              field: "code",
              headerName: "Folio",
              flex: 0.2,
            },
            {
              field: "arrivalDate",
              headerName: "Fecha de entrega",
              flex: 0.2,
              ...dateFormatter,
            },
            {
              field: "providerCode",
              headerName: "Proveedor",
              valueGetter: (value, row) => {
                return row.provider.code || "-";
              },
              flex: 0.4,
            },
            {
              field: "total",
              headerName: "Total",
              flex: 0.2,
              ...currencyFormatterMXN,
            },
            {
              field: "currency",
              headerName: "Moneda",
              flex: 0.1,
              valueGetter: (value, row) => {
                return row.currency.symbol || "-";
              },
            },
            {
              field: "status",
              headerName: "Estatus",
              flex: 0.2,
              renderCell: (params) => (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    size="small"
                    label={params.row.status.name.toUpperCase()}
                    color={setStatusColor(params.row.status.code)}
                  />
                </Box>
              ),
            },
          ],
          addModalData: {
            header: "Agregar Órden de compra",
            body: <PurchaseOrderFormV2 />,
          },
          editModalData: {
            header: "Editar Órden de compra",
            body: <POUpdateForm />,
          },
        }}
      />
    </Box>
  );
}
