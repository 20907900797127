import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import LGCrud from "../LGCrud";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import {
  createFinishedGood,
  getFinishedGoodList,
  updateFinishedGood,
  deleteFinishedGood,
} from "../../api/finishedGoods";

export default function CrudFinishedGoods({ props }) {
  const { companyId } = props;

  const [list, setList] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    const res = await getFinishedGoodList({ companyId });
    setList(res.data);
  };

  const handlePost = async (data) => {
    const payload = {
      companyId,
      ...data,
    };
    try {
      const res = await createFinishedGood(payload);
      openSnack("Producto terminado guardado con éxito", "success");
      fetchData();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = {
      ...data,
    };
    try {
      const res = await updateFinishedGood(id, payload);
      openSnack("Producto terminado actualizado con éxito", "success");
      fetchData();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteFinishedGood(id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box width={"100%"}>
        <LGCrud
          data={list}
          columns={[
            {
              field: "name",
              headerName: "Nombre",
              flex: 0.7,
              editable: true,
            },
            {
              field: "code",
              headerName: "Clave",
              flex: 0.3,
              editable: true,
            },
          ]}
          actionHandlers={{
            create: handlePost,
            update: handleUpdate,
            delete: handleDelete,
          }}
        />
      </Box>
    </>
  );
}
