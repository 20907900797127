import React from "react";
import PlantList from "../organisms/Enterprise/PlantList";
import LoginScreen from "../screens/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import CompanyScreen from "../screens/Enterprise/Company";
import { Box, Icon } from "@mui/material";
import HomeScreen from "../screens/Home";
import ProviderScreen from "../screens/Providers";
import CataloguesScreen from "../screens/Catalogues";
import ProductScreen from "../screens/Products";
import ShipmentScreen from "../screens/Imports/ShipmentScreen";
import DashboardLayout from "../layouts/DashboardLayout";
import PurchaseOrderScreen from "../screens/PurchaseOrders/PurchaseOrderScreen";
import PODetailScreen from "../screens/PurchaseOrders/PODetailScreen";
import PONewScreen from "../screens/PurchaseOrders/PONewScreen";
import AssetScreen from "../screens/Catalogues/AssetScreen";
import ProviderForm from "../organisms/Providers/ProviderForm";
import ManageUsersScreen from "../screens/Auth/ManageUsersScreen";
import ReceiptAcknowledgementForm from "../organisms/ReceiptAcknowledgement/REceiptAckowledgementForm";
import RAScreen from "../screens/ReceiptAcknowledgement/RAScreen";
import ClientScreen from "../screens/Clients/ClientScreen";
import FinishedGoodScreen from "../screens/FinishedGoods/FinishedGoodScreen";
import ExportForm from "../organisms/Exports/ExportForm";
import ExportScreen from "../screens/Exports/ExportScreen";

const baseRoles = ["Superadmin", "Admin"];

const ENV = process.env.REACT_APP_ENV;

const routes = [
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/register",
    element: <RegisterScreen />,
    roles: ["Superadmin"],
    menu: {
      title: "Registrar usuario",
      icon: <Icon>person_add</Icon>,
    },
  },
  {
    path: "/user-config",
    element: <ManageUsersScreen />,
    roles: baseRoles,
    menu: {
      title: "Administrar usuarios",
      icon: <Icon>manage_accounts_icon</Icon>,
    },
  },
  {
    path: "/home",
    element: <HomeScreen />,
    roles: baseRoles,
  },
  {
    path: "/providers",
    element: <ProviderScreen />,
    roles: [...baseRoles, "Compras", "Cuentas por pagar"],
    menu: {
      title: "Proveedores",
      icon: <Icon>warehouse</Icon>,
    },
  },
  {
    path: "/clients",
    element: <ClientScreen />,
    roles: [...baseRoles],
    menu: {
      title: "Clientes",
      icon: <Icon>person</Icon>,
    },
  },
  {
    path: "/products",
    element: <ProductScreen />,
    roles: baseRoles,
    menu: {
      title: "Productos",
      icon: <Icon>business_center</Icon>,
    },
  },
  {
    path: "/finished-goods",
    element: <FinishedGoodScreen />,
    roles: baseRoles,
    menu: {
      title: "Productos terminados",
      icon: <Icon>business_center</Icon>,
    },
  },
  {
    path: "/purchase-orders",
    element: <PurchaseOrderScreen />,
    roles: [...baseRoles, "Compras", "Cuentas por pagar"],
    menu: {
      title: "Órdenes de compra",
      icon: <Icon>shopping_bag</Icon>,
    },
  },
  {
    path: "/receipt-acknowledgements",
    element: <RAScreen />,
    roles: [...baseRoles, "Contador"],
    menu: {
      title: "Contrarrecibos",
      icon: <Icon>receipt_long</Icon>,
    },
  },
  {
    path: "/purchase-orders/concepts",
    element: <PODetailScreen />,
    roles: [...baseRoles, "Compras"],
  },
  {
    path: "/imports",
    element: <ShipmentScreen />,
    roles: [...baseRoles],
    menu: {
      title: "Importaciones",
      icon: <Icon>flight_land</Icon>,
    },
  },
  {
    path: "/exports",
    element: <ExportScreen />,
    roles: [...baseRoles],
    menu: {
      title: "Exportaciones",
      icon: <Icon>flight_takeoff</Icon>,
    },
  },
  {
    path: "/plants",
    element: <PlantList />,
    roles: [...baseRoles, "User"],
  },
  {
    path: "/enterprise",
    element: <CompanyScreen />,
    roles: ["Superadmin"],
    menu: {
      title: "Empresa",
      icon: <Icon>factory</Icon>,
    },
  },
  {
    path: "/catalogues",
    element: <CataloguesScreen />,
    roles: baseRoles,
    menu: {
      title: "Catálogos",
      icon: <Icon>all_inbox</Icon>,
    },
  },
  {
    path: "/assets",
    element: <AssetScreen />,
    roles: baseRoles,
    menu: {
      title: "Activos",
      icon: <Icon>emoji_transportation_icon</Icon>,
    },
  },
  {
    path: "/unauthorized",
    element: <PlantList />,
    roles: ["User"],
  },
];

if (ENV == "LOCAL") {
  routes.push({
    path: "/lab",
    element: (
      <DashboardLayout>
        <Box sx={{ p: 8 }}>
          <ExportForm />
        </Box>
      </DashboardLayout>
    ),
    roles: baseRoles,
    menu: {
      title: "Laboratorio",
      icon: <Icon>science</Icon>,
    },
  });
}

export default routes;
