import React, { useEffect, useState } from "react";
import { FieldArray, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Checkbox,
  Grid,
  Typography,
  Box,
  IconButton,
  Stack,
  Icon,
  Divider,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import {
  addShipment,
  getShipmentDetail,
  updateShipment,
} from "../../api/imports";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getProviderList } from "../../api/providers";
import { getCatalogue, getStatusCatalogue } from "../../api/common/catalogues";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import CompanyDropdownSelector from "../../atoms/Common/CompanyDropdownSelector";
import { formatCurrency } from "../../services/valueFormatters";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

const validationSchema = Yup.object().shape({
  companyId: Yup.number().required("Seleccione una empresa"),
  statusId: Yup.number(),
  purchaseOrder: Yup.string(),
  invoiceNo: Yup.string(),
  ref: Yup.string().required("Referencia es obligatoria"),
  foreignTransportId: Yup.number().nullable(),
  customsAgentId: Yup.number().nullable(),
  shipmentTypeId: Yup.number().nullable(),
  shipmentModeId: Yup.number().nullable(),
  masterBillofLanding: Yup.string(),
  forwarderId: Yup.number().nullable(),
  forwarderGuideNo: Yup.string(),
  portArrivalEta: Yup.date().required(
    "Fecha estimada de arribo es obligatoria"
  ),
  plantArrivalDate: Yup.date().nullable(),
  isFsc: Yup.boolean(),
  observations: Yup.string(),
  providerId: Yup.string().required("Seleccione un proveedor"),
  hasDelay: Yup.boolean(),
  delayCharges: Yup.number()
    .max(99999, "El monto debe de ser menor a $100,000.00")
    .min(0, "El monto no debe ser negativo"),
  containers: Yup.array().of(
    Yup.object().shape({
      code: Yup.string(),
    })
  ),
});

const initialValues = {
  companyId: null,
  purchaseOrder: "",
  invoiceNo: "",
  ref: "",
  foreignTransportId: null,
  customsAgentId: null,
  shipmentTypeId: null,
  shipmentModeId: null,
  masterBillofLanding: "",
  forwarderId: null,
  forwarderGuideNo: "",
  portArrivalEta: null,
  plantArrivalDate: null,
  isFsc: false,
  observations: "",
  providerId: "",
  hasDelay: false,
  delayCharges: 0,
  statusId: 0,
  containers: [{ code: "" }],
};

const ShipmentForm = ({ onSubmit, id }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [providerOptions, setProviderOptions] = useState([]);

  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getShipmentDetail(id);
      if (res.data.plantArrivalDate == "0001-01-01") {
        res.data.plantArrivalDate = dayjs();
      }
      setFormData(res.data);
      await fetchProviderOptions(res.data.provider.code);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProviderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleSubmit = async (values) => {
    const formattedDate = values.portArrivalEta
      ? dayjs(values.portArrivalEta).format("YYYY-MM-DD")
      : "";
    const formattedArrivalDate = values.plantArrivalDate
      ? dayjs(values.plantArrivalDate).format("YYYY-MM-DD")
      : "";
    values.portArrivalEta = formattedDate;
    values.plantArrivalDate = formattedArrivalDate;
    if (formMode == "insert") {
      const payload = {
        shipmentData: { ...values },
        containers: values.containers,
      };
      delete payload.shipmentData.containers;

      try {
        await addShipment(payload);
        openSnack("Embarque guardado exitosamente", "success");
        onSubmit();
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    } else {
      const payload = {
        shipmentData: { ...values },
        containers: values.containers,
      };
      try {
        await updateShipment(id, payload);
        openSnack("Embarque actualizado exitosamente", "success");
        onSubmit();
      } catch (e) {
        console.log(e);

        openSnack(handleError(e), "error");
      }
    }
  };

  useEffect(() => {
    fetchProviderOptions();
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        ...formData,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid container spacing={2} item xs={8} pr={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Datos Generales</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="companyId"
                  label="Empresa"
                  as={CompanyDropdownSelector}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="statusId"
                  label="Estatus"
                  as={DropdownSelector}
                  fetchFunction={() => getStatusCatalogue("SHIPMENT")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="purchaseOrder"
                  name="purchaseOrder"
                  label="Orden de compra"
                  value={values.purchaseOrder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.purchaseOrder && Boolean(errors.purchaseOrder)}
                  helperText={touched.purchaseOrder && errors.purchaseOrder}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="masterBillofLanding"
                  name="masterBillofLanding"
                  label="Master Bill of Landing"
                  value={values.masterBillofLanding}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.masterBillofLanding &&
                    Boolean(errors.masterBillofLanding)
                  }
                  helperText={
                    touched.masterBillofLanding && errors.masterBillofLanding
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="ref"
                  name="ref"
                  label="Referencia"
                  value={values.ref}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ref && Boolean(errors.ref)}
                  helperText={touched.ref && errors.ref}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="providerId"
                  name="providerId"
                  options={providerOptions}
                  getOptionLabel={(option) => option.code || ""}
                  value={
                    providerOptions.find(
                      (option) => option.id === values.providerId
                    ) || null
                  }
                  onInputChange={(event, value) => fetchProviderOptions(value)}
                  onChange={(event, value) => {
                    setFieldValue("providerId", value ? value.id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Proveedor"
                      error={touched.providerId && Boolean(errors.providerId)}
                      helperText={touched.providerId && errors.providerId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="invoiceNo"
                  name="invoiceNo"
                  label="No. Factura"
                  value={values.invoiceNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.invoiceNo && Boolean(errors.invoiceNo)}
                  helperText={touched.invoiceNo && errors.invoiceNo}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="foreignTransportId"
                  label="Naviera / Aerolinea"
                  as={DropdownSelector}
                  fetchFunction={() => getCatalogue("FOREIGN_TRANSPORT")}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="customsAgentId"
                  label="Agente Aduanal"
                  as={DropdownSelector}
                  fetchFunction={() => getCatalogue("CUSTOMS_AGENT")}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="forwarderId"
                  label="Forwarder"
                  as={DropdownSelector}
                  fetchFunction={() => getCatalogue("FORWARDER")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="forwarderGuideNo"
                  name="forwarderGuideNo"
                  label="No. Guia Forwarder"
                  value={values.forwarderGuideNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.forwarderGuideNo && Boolean(errors.forwarderGuideNo)
                  }
                  helperText={
                    touched.forwarderGuideNo && errors.forwarderGuideNo
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="shipmentTypeId"
                  label="Tipo de Embarque"
                  as={DropdownSelector}
                  fetchFunction={() => getCatalogue("SHIPMENT_TYPE")}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="shipmentModeId"
                  label="Modalidad"
                  as={DropdownSelector}
                  fetchFunction={() => getCatalogue("SHIPMENT_MODE")}
                />
              </Grid>
              <Grid item xs={6}>
                <MyDatePicker
                  fullWidth
                  slotProps={{ textField: { fullWidth: true } }}
                  label="Fecha Estimada a Puerto"
                  value={
                    values.portArrivalEta ? dayjs(values.portArrivalEta) : null
                  }
                  onChange={(value) => setFieldValue("portArrivalEta", value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  name="isFsc"
                  checked={values.isFsc}
                  onChange={handleChange}
                />
                <label htmlFor="isFsc">Certificado FSC</label>
              </Grid>
              {formMode == "edit" && (
                <>
                  <Grid item xs={6}>
                    <Checkbox
                      name="hasDelay"
                      checked={values.hasDelay}
                      onChange={handleChange}
                    />
                    <label htmlFor="hasDelay">¿Tiene demoras?</label>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="delayCharges"
                      component={CurrencyFieldText}
                      label="Cargos por retraso (USD)"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyDatePicker
                      fullWidth
                      slotProps={{ textField: { fullWidth: true } }}
                      label="Fecha de entrega"
                      value={
                        values.plantArrivalDate
                          ? dayjs(values.plantArrivalDate)
                          : null
                      }
                      onChange={(value) =>
                        setFieldValue("plantArrivalDate", value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  id="observations"
                  name="observations"
                  label="Observaciones"
                  value={values.observations}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid container spacing={2} item xs={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Contenedores</Typography>
              </Grid>
              <Grid item xs={12}>
                <FieldArray name="containers">
                  {({ push, remove }) => (
                    <Box>
                      <Grid container spacing={2}>
                        {values.containers &&
                          values.containers.map((_, index) => (
                            <Grid item xs={12} key={index}>
                              <Stack direction={"row"}>
                                <TextField
                                  fullWidth
                                  name={`containers.${index}.code`}
                                  label={`Contenedor ${index + 1}`}
                                  value={values.containers[index].code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.containers?.[index]?.code &&
                                    Boolean(errors.containers?.[index]?.code)
                                  }
                                  helperText={
                                    touched.containers?.[index]?.code &&
                                    errors.containers?.[index]?.code
                                  }
                                />
                                <IconButton
                                  onClick={() => remove(index)}
                                  color="error"
                                >
                                  <Icon>delete</Icon>
                                </IconButton>
                              </Stack>
                            </Grid>
                          ))}
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => push({ code: "" })}
                            color="primary"
                          >
                            <Icon>add</Icon>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ShipmentForm;
