import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  Button,
  Icon,
} from "@mui/material";
import { Field } from "formik";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import {
  getPurchaseOrderDetail,
  getPurchaseOrderList,
} from "../../api/purchaseOrders";
import { getProviderDetail, getProviderList } from "../../api/providers";
import { getCatalogue } from "../../api/common/catalogues";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const RAHeader = ({ values, setFieldValue, touched, errors }) => {
  const [providerOptions, setProviderOptions] = useState([]);
  const [purchaseOrderOptions, setPurchaseOrderOptions] = useState([]);

  const { openSnack } = useSnack();

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProviderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchPurchaseOrders = async (inputValue) => {
    console.log(values);

    try {
      const res = await getPurchaseOrderList({
        code: inputValue ? inputValue.toUpperCase() : null,
        companyId: values.receiptAcknowledgement.companyId,
      });
      setPurchaseOrderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handlePurchaseOrderChange = async (purchaseOrderId) => {
    if (!purchaseOrderId) return;

    try {
      const purchaseOrder = await getPurchaseOrderDetail(purchaseOrderId);

      if (purchaseOrder?.data) {
        setFieldValue("selectedPurchaseOrder", purchaseOrder.data);
      }

      if (purchaseOrder?.data.providerId) {
        const providerId = purchaseOrder.data.providerId;
        await fetchProviderOptions(purchaseOrder.data.provider.code);

        setFieldValue("receiptAcknowledgement.providerId", providerId);

        const providerData = await getProviderDetail(
          providerId,
          purchaseOrder.data.companyId
        );

        setFieldValue("selectedProvider", providerData.data ?? "");
      }
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  // handleProviderChange = async (providerId) => {
  //   if (!purchaseOrderId) return;
  //   try {
  //     const purchaseOrder = await getPurchaseOrderDetail(purchaseOrderId);

  //     if (purchaseOrder?.data) {
  //       setFieldValue("selectedPurchaseOrder", purchaseOrder.data);
  //     }

  //     if (purchaseOrder?.data.providerId) {
  //       const providerId = await purchaseOrder.data.providerId;

  //       setFieldValue("receiptAcknowledgement.providerId", providerId);

  //       const providerData = await getProviderDetail(
  //         providerId,
  //         purchaseOrder.data.companyId
  //       );

  //       setFieldValue("selectedProvider", providerData.data ?? "");
  //     }
  //   } catch (e) {
  //     openSnack(handleError(e), "error");
  //   }
  // };

  const handleDataUpdate = async () => {
    try {
      const RAItems = generateRAItems(values.selectedPurchaseOrder.items);
      setFieldValue("items", RAItems ?? []);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const generateRAItems = (POItems) => {
    const groupedData = POItems.reduce((acc, item) => {
      const expenseAccountId = item.expenseAccountId;

      let group = acc.find((obj) => obj.expenseAccountId === expenseAccountId);
      if (!group) {
        group = {
          expenseAccountId: expenseAccountId,
          providerAccountId: "",
          amount: 0,
          taxRate: 0,
        };
        acc.push(group);
      }

      group.amount += item.subtotal;
      group.taxRate = item.taxRate;

      return acc;
    }, []);

    return groupedData;
  };

  const handleSelectPurchaseOrder = (event, value) => {
    setFieldValue(
      "receiptAcknowledgement.purchaseOrderId",
      value ? value.id : ""
    );
  };

  useEffect(() => {
    fetchProviderOptions();
    fetchPurchaseOrders();
  }, []);

  useEffect(() => {
    if (values.receiptAcknowledgement.purchaseOrderId) {
      handlePurchaseOrderChange(values.receiptAcknowledgement.purchaseOrderId);
    }
  }, [values.receiptAcknowledgement.purchaseOrderId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <Autocomplete
          id="receiptAcknowledgement.purchaseOrderId"
          name="receiptAcknowledgement.purchaseOrderId"
          options={purchaseOrderOptions}
          getOptionLabel={(option) => option.code || ""}
          value={
            purchaseOrderOptions.find(
              (option) =>
                option.id === values.receiptAcknowledgement.purchaseOrderId
            ) || null
          }
          onInputChange={(event, value) => fetchPurchaseOrders(value)}
          onChange={handleSelectPurchaseOrder}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Orden de compra"
              // TODO: Checar porque causa error
              // error={
              //   touched.receiptAcknowledgement.purchaseOrderId &&
              //   Boolean(errors.receiptAcknowledgement.purchaseOrderId)
              // }
              // helperText={
              //   touched.receiptAcknowledgement.purchaseOrderId &&
              //   errors.receiptAcknowledgement.purchaseOrderId
              // }
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          sx={{ height: "100%" }}
          variant="contained"
          endIcon={<Icon>system_update_alt</Icon>}
          onClick={handleDataUpdate}
          disabled={!values.receiptAcknowledgement.purchaseOrderId}
        >
          Cargar datos
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          id="receiptAcknowledgement.providerId"
          name="receiptAcknowledgement.providerId"
          options={providerOptions}
          getOptionLabel={(option) => option.code || ""}
          value={
            providerOptions.find(
              (option) => option.id === values.receiptAcknowledgement.providerId
            ) || null
          }
          onInputChange={(event, value) => fetchProviderOptions(value)}
          onChange={(event, value) => {
            setFieldValue("selectedProvider", value ?? "");
            setFieldValue(
              "receiptAcknowledgement.providerId",
              value ? value.id : ""
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Proveedor"
              // TODO: Checar porque causa error

              // error={
              //   touched.receiptAcknowledgement.providerId &&
              //   Boolean(errors.receiptAcknowledgement.providerId)
              // }
              // helperText={
              //   touched.receiptAcknowledgement.providerId &&
              //   errors.receiptAcknowledgement.providerId
              // }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="receiptAcknowledgement.raTypeId"
          label="Tipo de Contrarrecibo"
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={"RA_TYPE"}
          // catalogueName={"RA_TYPE"}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="receiptAcknowledgement.invoiceNo">
          {({ field }) => (
            <TextField
              fullWidth
              label="Número de Factura"
              {...field}
              variant="outlined"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="receiptAcknowledgement.invoiceDate">
          {({ field }) => (
            <TextField
              fullWidth
              label="Fecha de Factura"
              type="date"
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="receiptAcknowledgement.paymentDate">
          {({ field }) => (
            <TextField
              fullWidth
              label="Fecha de Pago"
              type="date"
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="receiptAcknowledgement.beneficiary">
          {({ field }) => (
            <TextField
              fullWidth
              label="Beneficiario"
              {...field}
              variant="outlined"
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default RAHeader;
