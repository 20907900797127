import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import PurchaseOrderTable from "../../organisms/PurchaseOrders/PurchaseOrderTable";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import { useCompany } from "../../contexts/CompanyContext";

export default function PurchaseOrderScreen() {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompany } = useCompany();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label={`ODC ${selectedCompany.name}`} />
        </Tabs>
      </Box>

      <LGTabPanel value={activeTab} index={0}>
        <PurchaseOrderTable companyId={selectedCompany.id} />
      </LGTabPanel>
    </DashboardLayout>
  );
}
