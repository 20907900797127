import React, { useEffect, useState } from "react";
import LGSelectCellRenderer from "../../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../../LGCrud/LGSelectCellEditor";
import {
  addUserConfig,
  deleteUserConfig,
  getUserConfig,
  updateUserConfig,
} from "../../../api/auth";
import LGCrud from "../../LGCrud";
import { getCatalogue } from "../../../api/common/catalogues";
import { getCompanyList } from "../../../api/enterprise";
import {
  currencyFormatterMXN,
  percentageFormatter,
} from "../../../services/valueFormatters";

export default function CrudUserConfig({ id }) {
  const [userConfigList, setUserConfigList] = useState([]);
  const [configTypes, setConfigTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isCreating, setIsCreating] = useState(true); // new state for handling insert/update mode

  const fetchUserConfig = async () => {
    const res = await getUserConfig({ userId: id });
    setUserConfigList(res.data);
  };

  const fetchDropdownData = async () => {
    const res = await getCatalogue("USER_CONFIG");
    setConfigTypes(res.data);
    const companies = await getCompanyList();
    setCompanies(companies.data);
  };

  const handlePost = async (data) => {
    setIsCreating(true); // set flag to true when inserting
    const payload = { ...data };
    payload.userId = id;
    payload.companyId = payload.company.id;
    payload.configKeyId = payload.configKey.id;
    const res = await addUserConfig(payload);
    // setIsCreating(false); // reset flag after creation
  };

  const handleUpdate = async (id, data) => {
    setIsCreating(false); // set flag to false when updating
    const payload = { ...data };
    payload.companyId = payload.company.id;
    payload.configKeyId = payload.configKey.id;
    const res = await updateUserConfig(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteUserConfig(id);
  };

  useEffect(() => {
    if (isCreating) {
      fetchUserConfig();
      fetchDropdownData();
    }
  }, [isCreating]);

  return (
    <LGCrud
      data={userConfigList}
      columns={[
        {
          field: "company",
          headerName: "Empresa",
          editable: isCreating, // editable only during creation
          flex: 0.5,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v?.name || ""}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={companies}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
        },
        {
          field: "configKey",
          headerName: "Concepto",
          editable: isCreating, // editable only during creation
          flex: 0.5,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v?.name || ""}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={configTypes}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
        },
        {
          field: "configValue",
          headerName: "Valor",
          flex: 0.5,
          editable: true, // always editable
          ...currencyFormatterMXN,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
