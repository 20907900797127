import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import {
  addExport,
  createExport,
  getExportById,
  getExportDetail,
  updateExport,
} from "../../api/exports";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getCatalogue, getStatusCatalogue } from "../../api/common/catalogues";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import CompanyDropdownSelector from "../../atoms/Common/CompanyDropdownSelector";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import { getClientList } from "../../api/client";
import PropTypes from "prop-types";
import TariffFractionForm from "./TariffFractionForm";
import { getFinishedGoodList } from "../../api/finishedGoods";

const validationSchema = Yup.object().shape({
  clientId: Yup.number().required("Seleccione un cliente"),
  statusId: Yup.number().required("Seleccione un estado"),
  purchaseOrder: Yup.string().required("Orden de compra es obligatoria"),
  forwarderId: Yup.number().required("Seleccione un forwarder"),
  packingListDate: Yup.date().required("Fecha de packing list es obligatoria"),
  agentDeliveryDate: Yup.date().required("Fecha de entrega es obligatoria"),
  invoiceDate: Yup.date().required("Fecha de factura es obligatoria"),
  quantity: Yup.number().required("Cantidad es obligatoria").positive(),
  palletQuantity: Yup.number()
    .required("Cantidad de pallets es obligatoria")
    .positive(),
  finishedGoodsId: Yup.number().required("Seleccione un producto terminado"),
  destinationId: Yup.number().required("Seleccione un destino"),
  containerTypeId: Yup.number().required("Seleccione un tipo de contenedor"),
  shipmentTypeId: Yup.number().required("Seleccione un tipo de embarque"),
  volume: Yup.number().required("Volumen es obligatorio").positive(),
  // FRACCIÓN ARANCELARIA
  requestNo: Yup.string(),
  tariffFraction: Yup.string().required("Fracción arancelaria es obligatoria"),
  invoiceAmount: Yup.number()
    .required("Monto de factura es obligatorio")
    .positive(),
  netWeight: Yup.number().required("Peso neto es obligatorio").positive(),
  lockNo: Yup.string(),
});

const initialValues = {
  clientId: null,
  statusId: null,
  purchaseOrder: "",
  forwarderId: null,
  packingListDate: null,
  agentDeliveryDate: null,
  invoiceDate: null,
  quantity: 0,
  palletQuantity: 0,
  finishedGoodsId: null,
  destinationId: null,
  containerTypeId: null,
  shipmentTypeId: null,
  volume: 0,
  // FRACCIÓN ARANCELARIA   
  requestNo: "",
  tariffFraction: "",
  invoiceAmount: 0,
  netWeight: 0,
  lockNo: "",
};

const ExportForm = ({ onSubmit, id, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [clientOptions, setClientOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getExportById(id);
      setFormData(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const fetchClientOptions = async (inputValue) => {
    try {
      const res = await getClientList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setClientOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleSubmit = async (values) => {
    const formatDate = (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "");

    const formattedValues = {
      ...values,
      companyId,
      packingListDate: formatDate(values.packingListDate),
      agentDeliveryDate: formatDate(values.agentDeliveryDate),
      invoiceDate: formatDate(values.invoiceDate),
    };

    try {
      if (formMode === "insert") {
        await createExport(formattedValues);
        openSnack("Exportación guardada exitosamente", "success");
      } else {
        await updateExport(id, formattedValues);
        openSnack("Exportación actualizada exitosamente", "success");
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchClientOptions();
    if (formMode === "edit") {
      fetchData();
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Datos de Exportación</Typography>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="statusId"
                label="Estatus"
                as={DropdownSelector}
                fetchFunction={() => getStatusCatalogue("EXPORT")}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="clientId"
                options={clientOptions}
                getOptionLabel={(option) => option.code || ""}
                value={
                  clientOptions.find(
                    (option) => option.id === values.clientId
                  ) || null
                }
                onInputChange={(event, value) => fetchClientOptions(value)}
                onChange={(event, value) => {
                  setFieldValue("clientId", value ? value.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    error={touched.clientId && Boolean(errors.clientId)}
                    helperText={touched.clientId && errors.clientId}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="purchaseOrder"
                label="Orden de Compra"
                value={values.purchaseOrder}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.purchaseOrder && Boolean(errors.purchaseOrder)}
                helperText={touched.purchaseOrder && errors.purchaseOrder}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name="forwarderId"
                label="Forwarder"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("EXPORT_FORWARDER")}
              />
            </Grid>

            <Grid item xs={6}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de Packing List"
                value={
                  values.packingListDate ? dayjs(values.packingListDate) : null
                }
                onChange={(value) => setFieldValue("packingListDate", value)}
              />
            </Grid>

            <Grid item xs={6}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de Entrega"
                value={
                  values.agentDeliveryDate ? dayjs(values.agentDeliveryDate) : null
                }
                onChange={(value) => setFieldValue("agentDeliveryDate", value)}
              />
            </Grid>

            <Grid item xs={6}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de Factura"
                value={
                  values.invoiceDate ? dayjs(values.invoiceDate) : null
                }
                onChange={(value) => setFieldValue("invoiceDate", value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="quantity"
                label="Cantidad"
                type="number"
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.quantity && Boolean(errors.quantity)}
                helperText={touched.quantity && errors.quantity}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="palletQuantity"
                label="Cantidad de Pallets"
                type="number"
                value={values.palletQuantity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.palletQuantity && Boolean(errors.palletQuantity)}
                helperText={touched.palletQuantity && errors.palletQuantity}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name="finishedGoodsId"
                label="Producto Terminado"
                as={DropdownSelector}
                fetchFunction={getFinishedGoodList}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name="destinationId"
                label="Destino"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("EXPORT_DESTINATIONS")}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name="containerTypeId"
                label="Tipo de Contenedor"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("EXPORT_CONTAINER_TYPE")}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name="shipmentTypeId"
                label="Tipo de Embarque"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("EXPORT_SHIPMENT_TYPE")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="volume"
                label="Volumen"
                type="number"
                value={values.volume}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.volume && Boolean(errors.volume)}
                helperText={touched.volume && errors.volume}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Fracción Arancelaria</Typography>
              <Box sx={{ mt: 2 }}>
                <TariffFractionForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                {formMode === "edit" ? "Actualizar" : "Crear"} Exportación
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ExportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.number,
  companyId: PropTypes.number.isRequired,
};

export default ExportForm;
