import React from "react";
import { Grid, TextField } from "@mui/material";
import { Field } from "formik";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import PropTypes from "prop-types";

const TariffFractionForm = ({ values, handleChange, handleBlur, touched, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="requestNo"
          label="Número de Solicitud"
          value={values.requestNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.requestNo && Boolean(errors.requestNo)}
          helperText={touched.requestNo && errors.requestNo}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="tariffFraction"
          label="Fracción Arancelaria"
          value={values.tariffFraction}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.tariffFraction && Boolean(errors.tariffFraction)}
          helperText={touched.tariffFraction && errors.tariffFraction}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="invoiceAmount"
          component={CurrencyFieldText}
          label="Monto de Factura"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="netWeight"
          label="Peso Neto"
          type="number"
          value={values.netWeight}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.netWeight && Boolean(errors.netWeight)}
          helperText={touched.netWeight && errors.netWeight}
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="lockNo"
          label="Número de Candado"
          value={values.lockNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lockNo && Boolean(errors.lockNo)}
          helperText={touched.lockNo && errors.lockNo}
        />
      </Grid>
    </Grid>
  );
};

TariffFractionForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default TariffFractionForm;
