import { api } from "..";

const endpoint = "/api/receipt-acknowledgements";

// RA HEADER
export const getReceiptAcknowledgements = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const getReceiptAcknowledgementDetail = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addReceiptAcknowledgement = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateReceiptAcknowledgement = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteReceiptAcknowledgement = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

// RA ITEMS

export const exportReceiptAcknowledgementTxt = async (params) => {
  const response = await api.get(`${endpoint}/export/txt`, {
    params,
    responseType: 'blob',
  });
  return response;
};
