import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import LGModalCrud from "../LGCrud/LGModalCrud";
import { deleteProduct, getProductList } from "../../api/products";
import AddProductForm from "./addProductForm";
import ProductForm from "./addProductForm";

export default function CrudProducts({ props }) {
  const { companyId } = props;

  return (
    <Box width={"100%"}>
      <LGModalCrud
        props={{
          actions: ["create", "update", "delete"],
          data: { companyId },
          fetchFunction: getProductList,
          deleteFunction: deleteProduct,
          //   detailMenu: [

          //     {
          //       label: "Dirección y Contacto",
          //       handler: handleOpenContactClick,
          //       header: "Contactos del proveedor",
          //       body: <ProviderContactList />,
          //     },
          //     {
          //       label: "Info. Bancaria",
          //       handler: handleOpenBankAccountClick,
          //       header: "Información bancaria",
          //       body: <BankAccountList />,
          //     },
          //     {
          //       label: "Actividades",
          //       handler: handleOpenActivitiesClick,
          //       header: "Actividades del proveedor",
          //       body: <ActivityTable />,
          //     },
          //   ],
          columns: [
            {
              field: "code",
              headerName: "Código",
              flex: 0.2,
            },
            {
              field: "name",
              headerName: "Nombre",
              flex: 0.2,
            },
            {
              field: "brand",
              headerName: "Marca",
              flex: 0.2,
            },
            {
              field: "productType",
              headerName: "Tipo de productos",
              flex: 0.1,
              editable: false,
              renderCell: (params) => (
                <Chip
                  size="small"
                  label={params.row.productType.name}
                  color={
                    params.row.productType.name == "MÁQUINA"
                      ? "primary"
                      : params.row.productType.name == "Persona Moral"
                      ? "success"
                      : "secondary"
                  }
                />
              ),
            },
          ],
          addModalData: {
            header: "Agregar Producto",
            body: <AddProductForm />,
          },
          editModalData: {
            header: "Editar Producto",
            body: (
              // <Typography>EDITAR Producto</Typography>
              <ProductForm />
              // <UpdateProviderFromAccountForm
              //   props={{ handleUpdate: handleUpdate }}
              // />
            ),
          },
        }}
      />
    </Box>
  );
}
