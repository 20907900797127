import { Button, Icon } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

export default function LGCrudToolbar(props) {
  const { handlePost, customButtons } = props;
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handlePost}
      >
        Agregar
      </Button>
      {customButtons?.map((button, index) => (
        <Button
          key={index}
          color={button.color || "primary"}
          variant="contained"
          startIcon={button.icon && <Icon>{button.icon}</Icon>}
          onClick={button.onClick}
          sx={{ ml: 1 }}
        >
          {button.label}
        </Button>
      ))}
    </GridToolbarContainer>
  );
}
