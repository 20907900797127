import React, { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const DropdownSelector = ({
  label,
  optionLabel,
  optionSeparator = " ",
  parentOptions,
  fetchFunction,
  fetchParams = null,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const [options, setOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchFunction(fetchParams);
      setOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  }, [fetchFunction, fetchParams, openSnack]);

  useEffect(() => {
    if (parentOptions) {
      setOptions(parentOptions);
    } else {
      fetchData();
    }
  }, [fetchFunction, fetchParams]);

  const getOptionLabel = (option) => {
    if (Array.isArray(optionLabel)) {
      // If optionLabel is an array, join the values using optionSeparator
      return optionLabel
        .map((label) => option[label] || "")
        .join(optionSeparator);
    }
    // If optionLabel is a string, return the corresponding option value
    return option[optionLabel] || option.name;
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...field}
        {...props}
        value={field.value || ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        {options &&
          Array.isArray(options) &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getOptionLabel(option)}
            </MenuItem>
          ))}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default DropdownSelector;
