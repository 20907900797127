import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { login } from "../../../api/auth";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import { getUserRoles } from "../../../services/auth";

function LoginForm() {
  const { openSnack } = useSnack();
  const [loginData, setLoginData] = useState({ email: "", token: "" });

  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string("Ingresa un email válido")
      .email("Ingresa un email válido")
      .required("El email es requerido"),
    password: Yup.string("Ingresa una contraseña").required(
      "La contraseña es requerida"
    ),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await login(values.email, values.password);
        setLoginData(res.data);
        localStorage.setItem("accessToken", res.data.token);
        navigate("/home");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    },
  });

  useEffect(() => {}, []);

  return (
    <>
      <Typography component="h1" variant="h5">
        Ingresar
      </Typography>
      <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Iniciar Sesión
        </Button>
      </Box>
    </>
  );
}

export default LoginForm;
