import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, RouterProvider } from "react-router-dom";
import SnackProvider from "./services/SnakbarProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

dayjs.locale("es-MX");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
