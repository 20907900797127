import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

export default function RAExportModal({ companyId, onSubmit }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSubmit = () => {
    onSubmit({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      companyId,
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha inicial"
            value={startDate}
            onChange={(value) => setStartDate(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha final"
            value={endDate}
            onChange={(value) => setEndDate(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={!startDate || !endDate}
          >
            Exportar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
