import { api } from "..";

const account_uri = "/api/account";
const roles_uri = "/api/roles";
const users_uri = "api/users";
const config_uri = "api/user-configs";

export const login = (email, password) => {
  return api.post(`${account_uri}/login`, { email, password });
};

export const register = (data) => {
  var res = api.post(`${account_uri}/register`, { ...data });
  return res;
};

export const logout = () => {
  localStorage.removeItem("accessToken");
};

export const getRoles = () => {
  return api.get(`${roles_uri}`);
};

export const getUsers = () => {
  return api.get(`${users_uri}`);
};

// AUTH
export const getUserConfig = (queryParams) => {
  return api.get(`${config_uri}`, { params: queryParams });
};

export const addUserConfig = (data) => {
  return api.post(`${config_uri}`, data);
};

export const updateUserConfig = (id, data) => {
  return api.put(`${config_uri}/${id}`, data);
};

export const deleteUserConfig = (id) => {
  return api.delete(`${config_uri}/${id}`);
};
