// File: src/components/BankAccountItem/NationalBankItem.jsx
import React from "react";
import { Grid, Icon, Stack, Typography } from "@mui/material";

const NationalBankItem = ({ clabe, cie, cieRef }) => (
  <>
    <Grid container item xs={12}>
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        justifyContent="space-around"
      >
        <Icon>vpn_key</Icon>
        <Typography variant="button" color="primary.main">
          CLABE
        </Typography>
        <Typography>{clabe}</Typography>
      </Stack>
    </Grid>

    {cie && (
      <Grid container item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-evenly"
        >
          <Icon>compare_arrows</Icon>
          <Typography variant="button" color="primary.main">
            Cuenta CIE
          </Typography>
          <Typography>{cie}</Typography>
        </Stack>
      </Grid>
    )}

    {cieRef && (
      <Grid container item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-evenly"
        >
          <Icon>compare_arrows</Icon>
          <Typography variant="button" color="primary.main">
            Referencia CIE
          </Typography>
          <Typography>{cieRef}</Typography>
        </Stack>
      </Grid>
    )}
  </>
);

export default NationalBankItem;
