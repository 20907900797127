import React from "react";
import { FieldArray, Field } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  FormControlLabel,
  Icon,
} from "@mui/material";

const ProviderAccountList = ({
  values,
  errors,
  touched,
  setFieldValue,
  formMode,
}) => {
  const handleDefaultChange = (index) => {
    values.accounts.forEach((account, idx) => {
      setFieldValue(`accounts[${idx}].isDefault`, idx === index);
    });
  };

  return (
    <Grid container pl={2} spacing={2}>
      <FieldArray name="accounts">
        {({ push, remove }) => (
          <Box width="100%">
            {values.accounts.map((account, index) => (
              <Grid container spacing={2} key={index} alignItems="center">
                <Grid item xs={4}>
                  <Field
                    name={`accounts[${index}].contpaqiAccountNo`}
                    as={TextField}
                    label="Cuenta Contable"
                    fullWidth
                    // disabled={account.id}
                    error={
                      touched.accounts?.[index]?.contpaqiAccountNo &&
                      !!errors.accounts?.[index]?.contpaqiAccountNo
                    }
                    helperText={
                      touched.accounts?.[index]?.contpaqiAccountNo &&
                      errors.accounts?.[index]?.contpaqiAccountNo
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={`accounts[${index}].description`}
                    as={TextField}
                    label="Descripción"
                    fullWidth
                    error={
                      touched.accounts?.[index]?.description &&
                      !!errors.accounts?.[index]?.description
                    }
                    helperText={
                      touched.accounts?.[index]?.description &&
                      errors.accounts?.[index]?.description
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={account.isDefault}
                        onChange={() => handleDefaultChange(index)}
                      />
                    }
                    label="Predeterminado"
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => remove(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              fullWidth
              type="button"
              onClick={() =>
                push({
                  contpaqiAccountNo: "",
                  description: "",
                  isDefault: false,
                })
              }
              variant="contained"
              sx={{ mt: 2 }}
            >
              <Icon>add</Icon>
            </Button>
          </Box>
        )}
      </FieldArray>
    </Grid>
  );
};

export default ProviderAccountList;
