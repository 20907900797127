import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCompanyList } from '../api/enterprise';
import { getUserCompany } from '../services/auth';

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState({id: 1});
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const res = await getCompanyList();
        setCompanies(res.data);
        
        // Set initial company from user's default
        const userCompany = await getUserCompany();
        let initialCompany = res.data.find(c => c.id === userCompany) || res.data[0];
        
        // Load saved company ID from localStorage if it exists
        const savedCompanyId = localStorage.getItem('selectedCompanyId');
        if (savedCompanyId) {
          // Verify the saved company still exists in the current company list
          const existingCompany = res.data.find(c => c.id === parseInt(savedCompanyId));
          if (existingCompany) {
            initialCompany = existingCompany;
          }
        }

        // Save selected company ID to localStorage
        localStorage.setItem('selectedCompanyId', initialCompany.id);
        setSelectedCompany(initialCompany);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany, companies }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext); 