import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

export const getUserRoles = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);

    return { user: _.given_name, roles: _.roles ? JSON.parse(_.roles) : [] };
  }
  return { user: "", roles: [] };
};

export const isTokenExpired = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);
    return dayjs().isAfter(dayjs(_.exp * 1000));
  }
};

export const getUserCompany = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);
    return _.CompanyId;
  }
};
