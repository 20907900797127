import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import { useSnack } from "../../services/SnakbarProvider";
import { getCompanyList } from "../../api/enterprise";
import { handleError } from "../../services/errorHandler";
import { getUserCompany } from "../../services/auth";
import ReceiptAcknowledgementCrud from "../../organisms/ReceiptAcknowledgement/ReceiptAcknowledgementCrud";

export default function RAScreen() {
  const [value, setValue] = useState(1);
  const [companies, setCompanies] = useState([]);
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await getCompanyList();
      let companyAllowed = await getUserCompany();

      setCompanies(res.data);
      // setCompanies(res.data?.filter((e) => e.id == companyAllowed));
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Box p={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ textTransform: "uppercase" }}
        >
          Lista de contrarrecibos
        </Typography>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            {companies.map((e) => (
              <Tab label={e.name} value={e.id} key={e.id} />
            ))}
          </Tabs>
        </Box>
        <LGTabPanel value={value} index={1}>
          <ReceiptAcknowledgementCrud props={{ companyId: 1 }} />
        </LGTabPanel>
        <LGTabPanel value={value} index={2}>
          <ReceiptAcknowledgementCrud props={{ companyId: 2 }} />
        </LGTabPanel>

        <LGTabPanel value={value} index={3}>
          <ReceiptAcknowledgementCrud props={{ companyId: 3 }} />
        </LGTabPanel>
      </Box>
    </DashboardLayout>
  );
}
