import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import LGModalCrud from "../../organisms/LGCrud/LGModalCrud";
import { getUsers } from "../../api/auth";
import { Stack, Typography } from "@mui/material";
import CrudUserConfig from "../../organisms/Auth/CrudUserConfig/CrudUserConfig";
import RegisterForm from "../../organisms/Auth/RegisterForm";

export default function ManageUsersScreen() {
  return (
    <DashboardLayout>
      <LGModalCrud
        props={{
          actions: ["create", "update"],
          fetchFunction: getUsers,
          columns: [
            {
              field: "userName",
              headerName: "Nombre de usuario",
              flex: 0.2,
            },
            {
              field: "email",
              headerName: "Correo electrónico",
              flex: 0.2,
            },
            {
              field: "roles",
              headerName: "Roles",
              flex: 0.2,
              renderCell: (params) => (
                <Stack direction={"column"}>
                  {params.value.map((e) => (
                    <Typography variant="body2" key={e}>
                      {e}
                    </Typography>
                  ))}
                </Stack>
              ),
            },
          ],
          addModalData: {
            header: "Agregar Usuario",
            body: <RegisterForm />,
          },
          editModalData: {
            header: "Administrar Permisos",
            body: <CrudUserConfig />,
          },
        }}
      />
    </DashboardLayout>
  );
}
