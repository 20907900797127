import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import LGModalCrud from "../../organisms/LGCrud/LGModalCrud";
import CrudProducts from "../../organisms/Products";
import { useCompany } from "../../contexts/CompanyContext";

export default function ProductScreen() {
  const { selectedCompany } = useCompany();

  return (
    <DashboardLayout>
      <CrudProducts props={{ companyId: selectedCompany.id }} />
    </DashboardLayout>
  );
}
