import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import {
  getCatalogue,
  getCatalogueEntryById,
} from "../../api/common/catalogues";
import { getProductList } from "../../api/products";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getExpenseAccountList } from "../../api/accounting";

const POItemRow = ({ index, remove, activityIds }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const [productOptions, setProductOptions] = useState([]);
  const [activityOptions, setActivityOptions] = useState([]);
  const [assetCode, setAssetCode] = useState(0);
  const [assetOptions, setAssetOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchProductOptions = async (inputValue) => {
    try {
      const res = await getProductList({
        code: inputValue ? inputValue.toUpperCase() : null,
        ExpenseAccountIds: activityIds,
      });
      setProductOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchExpenseAccounts = async (activityIds) => {
    try {
      const res = await getExpenseAccountList({
        ExpenseAccountIds: activityIds,
      });
      setActivityOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchAssetOptions = async () => {
    try {
      const res = await getCatalogue();
      setAssetOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  // Fetch asset options when assetTypeId changes
  useEffect(() => {
    const assetTypeId = values.items?.[index]?.assetTypeId;
    if (assetTypeId) {
      getCatalogueEntryById(assetTypeId).then((res) => {
        setAssetCode(res.data.code);
        // getCatalogue(res.data.code)
        //   .then((res) => {
        //     setAssetCode(res.data);
        //     console.log(res.data);
        //   })
        //   .catch((e) => openSnack(handleError(e), "error"));
      });
    }
  }, [values.items?.[index]?.assetTypeId]); // Dependency on assetTypeId

  const handleDelete = () => {
    remove(index);
  };

  useEffect(() => {
    fetchProductOptions();
    fetchAssetOptions();
    // fetchExpenseAccounts();
  }, []);

  useEffect(() => {
    setAssetOptions(assetOptions.filter((e) => e.code == assetCode));
    console.log(assetCode);
  }, [assetCode]);

  return (
    <Grid container spacing={0}>
      {/* Actividades económicas */}
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={getExpenseAccountList}
          fetchParams={{ ExpenseAccountIds: activityIds }}
          optionLabel={["completeContpaqiCode", "name"]}
          optionSeparator=" | "
          name={`items.${index}.expenseAccountId`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      {/* Quantity */}
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.quantity`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      {/* Unit */}
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={"UNIT"}
          optionLabel="code"
          name={`items.${index}.unitId`}
          fullWidth
          size="small"
          margin="none"
        ></FastField>
      </Grid>
      {/* Concept */}
      <Grid item xs={2}>
        <FastField
          as={TextField}
          name={`items.${index}.concept`}
          fullWidth
          multiline
          size="small"
          margin="none"
        />
      </Grid>
      {/* Product */}
      <Grid item xs={1}>
        <Autocomplete
          size="small"
          margin="none"
          options={productOptions}
          getOptionLabel={(option) => option.code || ""}
          value={
            productOptions.find(
              (option) => option.id === values.items?.[index]?.productId
            ) || null
          }
          onInputChange={(event, value) => fetchProductOptions(value)}
          onChange={(event, value) => {
            setFieldValue(`items.${index}.productId`, value ? value.id : null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                touched.items?.[index]?.productId &&
                Boolean(errors.items?.[index]?.productId)
              }
              helperText={
                touched.items?.[index]?.productId &&
                errors.items?.[index]?.productId
              }
            />
          )}
        />
      </Grid>
      {/* AssetTypeId */}
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={"ASSET_TYPE"}
          name={`items.${index}.assetTypeId`}
          fullWidth
          size="small"
          margin="none"
        ></FastField>
      </Grid>
      {/* AssetId */}
      <Grid item xs={1}>
        <Field
          as={DropdownSelector}
          fetchFunction={getCatalogue}
          fetchParams={assetCode}
          name={`items.${index}.assetId`}
          fullWidth
          size="small"
          margin="none"
        ></Field>
      </Grid>
      {/* Price */}
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.price`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      {/* Work Order Id */}
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.workOrderId`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      {/* TaxRate */}
      <Grid item xs={1}>
        <FastField name={`items.${index}.taxRate`}>
          {({ field }) => (
            <FormControl
              fullWidth
              size="small"
              margin="none"
              variant="outlined"
            >
              <Select {...field} label="Tax Rate">
                {[0.0, 8.0, 16.0].map((rate) => (
                  <MenuItem key={rate} value={rate}>
                    {rate}% {/* Display the rate as a percentage */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </FastField>
      </Grid>
      <Grid item xs={1}>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(POItemRow);
