import { api } from "..";

const endpoint = "/api/purchase-orders";

// PURCHASE ORDERS
export const getPurchaseOrderList = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const getPurchaseOrderDetail = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addPurchaseOrder = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updatePurchaseOrder = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deletePurchaseOrder = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

// PO ITEMS
export const getPOItemList = (queryParams) => {
  return api.get(`${endpoint}/items`, { params: queryParams });
};

export const addPOItem = (data) => {
  return api.post(`${endpoint}/items`, data);
};

export const updatePOItem = (id, data) => {
  return api.put(`${endpoint}/items/${id}`, data);
};

export const deletePOItem = (id) => {
  return api.delete(`${endpoint}/items/${id}`);
};

// EXPORT TO PDF
export const exportPO = (id) => {
  return api.get(`${endpoint}/${id}/export`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};
