import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getProviderList } from "../../api/providers";
import { getCatalogue } from "../../api/common/catalogues";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { Formik, Form } from 'formik';

export default function ShipmentExportModal({ companyId, onSubmit }) {
  const [providerOptions, setProviderOptions] = useState([]);

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProviderOptions(res.data);
    } catch (e) {
      console.error("Error fetching providers:", e);
    }
  };

  const initialValues = {
    startDate: null,
    endDate: null,
    providerId: null,
    forwarderId: '',
    customsAgentId: '',
  };

  const handleSubmit = (values) => {
    onSubmit({
      startDate: values.startDate ? dayjs(values.startDate).format("YYYY-MM-DD") : null,
      endDate: values.endDate ? dayjs(values.endDate).format("YYYY-MM-DD") : null,
      providerId: values.providerId?.id || null,
      forwarderId: values.forwarderId || null,
      customsAgentId: values.customsAgentId || null,
      companyId,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MyDatePicker
                  fullWidth
                  slotProps={{ textField: { fullWidth: true } }}
                  label="Fecha inicial"
                  value={values.startDate}
                  onChange={(value) => setFieldValue('startDate', value)}
                />
              </Grid>
              <Grid item xs={6}>
                <MyDatePicker
                  fullWidth
                  slotProps={{ textField: { fullWidth: true } }}
                  label="Fecha final"
                  value={values.endDate}
                  onChange={(value) => setFieldValue('endDate', value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={providerOptions}
                  getOptionLabel={(option) => option.code || ""}
                  onInputChange={(event, value) => fetchProviderOptions(value)}
                  onChange={(event, value) => setFieldValue('providerId', value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Proveedor" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelector
                  name="forwarderId"
                  label="Forwarder"
                  fetchFunction={() => getCatalogue("FORWARDER")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelector
                  name="customsAgentId"
                  label="Agente Aduanal"
                  fetchFunction={() => getCatalogue("CUSTOMS_AGENT")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!values.startDate || !values.endDate}
                >
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
} 