import { TextField } from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

export const handleValueChange = (name, setFieldValue) => (val) =>
  setFieldValue(name, val.floatValue);

const CurrencyFieldText = ({ field, form, currencySymbol = "$", ...props }) => {
  const [displayValue, setDisplayValue] = useState(field.value);

  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <NumericFormat
      customInput={TextField}
      variant="outlined"
      isNumericString={true}
      thousandSeparator={true}
      value={displayValue}
      decimalScale={2}
      onValueChange={(vals) => {
        setDisplayValue(vals.formattedValue);
        form.setFieldValue(field.name, vals.floatValue);
      }}
      InputProps={{
        startAdornment: <span>{currencySymbol}</span>,
      }}
      {...props}
      error={Boolean(touched && error)}
      helperText={touched && error ? error : ""}
      {...props}
    />
  );
};

export default CurrencyFieldText;
