import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Grid, Checkbox, FormControlLabel, Button } from "@mui/material";
import ForeignBankForm from "./ForeignBankForm";
import NationalBankForm from "./NationalBankForm";
import * as Yup from "yup";
import { useSnack } from "../../../services/SnakbarProvider";
import { addProviderBankDetail } from "../../../api/providers";
import { handleError } from "../../../services/errorHandler";

const initialValues = {
  isForeign: false,
  bankId: "",
  foreignBank: "",
  contpaqiAccountNo: "",
  bankAccount: "",
  currencyId: 1,
  clabe: "",
  cie: "",
  cieRef: "",
  swift: "",
  aba: "",
  routingAba: "",
};

const validationSchema = Yup.object().shape({
  isForeign: Yup.boolean(),
  bankId: Yup.number().when("isForeign", {
    is: false,
    then: Yup.number()
      .required("ID del banco es requerido")
      .min(1, "Debe ser un valor positivo"),
    otherwise: Yup.number().nullable(),
  }),
  foreignBank: Yup.string().when("isForeign", {
    is: true,
    then: Yup.string().required("Banco extranjero es requerido"),
    otherwise: Yup.string().nullable(),
  }),
  bankAccount: Yup.string()
    .required("Cuenta bancaria es requerida")
    .min(10, "La cuenta bancaria debe tener al menos 10 caracteres"),
  currencyId: Yup.number()
    .required("ID de moneda es requerido")
    .min(1, "Debe ser un valor positivo"),
  clabe: Yup.string().when("isForeign", {
    is: false,
    then: Yup.string()
      .required("CLABE es requerida")
      .length(18, "La CLABE debe tener 18 caracteres"),
    otherwise: Yup.string().nullable(),
  }),
  cie: Yup.string().nullable(), // Optional
  cieRef: Yup.string().nullable(), // Optional
  swift: Yup.string().when("isForeign", {
    is: true,
    then: Yup.string()
      .required("SWIFT es requerido")
      .length(8, "El SWIFT debe tener 8 o 11 caracteres")
      .max(11, "El SWIFT debe tener 8 o 11 caracteres"),
    otherwise: Yup.string().nullable(),
  }),
  aba: Yup.string().when("isForeign", {
    is: true,
    then: Yup.string().nullable(), // Optional
    otherwise: Yup.string().nullable(),
  }),
  routingAba: Yup.string().when("isForeign", {
    is: true,
    then: Yup.string().nullable(), // Optional
    otherwise: Yup.string().nullable(),
  }),
});

const ProviderBankForm = ({ onSubmit, id }) => {
  // const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const { openSnack } = useSnack();

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await (id);
  //     if (res.data.plantArrivalDate == "0001-01-01") {
  //       res.data.plantArrivalDate = dayjs();
  //     }
  //     setFormData(res.data);
  //     await fetchProviderOptions(res.data.provider.code);
  //   } catch (e) {
  //     openSnack(handleError(e), "error");
  //   }
  //   setLoading(false);
  // };

  return (
    <Formik
      // validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const res = await addProviderBankDetail(values, id);
          openSnack("Cuenta de banco guardada exitosamente", "success");
          onSubmit();
        } catch (e) {
          openSnack(handleError(e), "error");
        }
        console.log("Submitted values:", values);
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Field as={Checkbox} name="isForeign" />}
                label="¿Es extranjero?"
              />
            </Grid>

            <Grid item xs={12}>
              {values.isForeign ? <ForeignBankForm /> : <NationalBankForm />}
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderBankForm;
