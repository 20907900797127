import { AppBar, Divider, IconButton, Stack, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { isTokenExpired } from "../../services/auth";
import { logout } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import CompanySelector from "../../components/CompanySelector";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useUser } from "../../context/UserContext";
import { useCompany } from "../../contexts/CompanyContext";

const ENV = process.env.REACT_APP_ENV;
const drawerWidth = 240;

export default function LGAppBar({ open, handleopenDrawer }) {
  const theme = useTheme();
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userData } = useUser();
  const { selectedCompany } = useCompany();

  const navigate = useNavigate();

  useEffect(() => {
    setAuth(true);
  }, [userData?.company]);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getEnvColor = () => {
    if (ENV === "LOCAL") {
      return theme.palette.purple?.[700] || "#34073D";
    } else if (ENV === "QA") {
      return theme.palette.success?.[700] || "#FF8700";
    }
    return theme.palette.primary.main;
  };

  const getLogo = () => {
    const companyprefix =
      selectedCompany.id == 1 ? "lg" : selectedCompany.id == 2 ? "tl" : "el";
    const envPrefix = ENV === "LOCAL" ? "dev" : ENV === "QA" ? "qa" : "prod";
    return `/assets/Imgs/logos/${companyprefix}-${envPrefix}.png`;
  };

  useEffect(() => {
    if (isTokenExpired()) {
      handleLogout();
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        height: "64px",
        backgroundColor: getEnvColor(),
        transition:
          "margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition:
            "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        }),
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleopenDrawer}
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          component="img"
          sx={{
            height: 40,
            width: "auto",
            mr: 2,
            display: { xs: "none", sm: "block" },
          }}
          src={getLogo()}
          alt="Company logo"
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {auth && (
          <div>
            <Stack direction="row" spacing={2} alignItems="center">
              {userData ? (
                <Typography>Bienvenido {userData.user}</Typography>
              ) : (
                <Typography>Sin sesión</Typography>
              )}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Stack>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {userData.roles && [
                <MenuItem key={"roles-title"}>Roles:</MenuItem>,
                <Divider key={"roles-divider"} />,
              ]}
              {userData.roles &&
                userData.roles.map((e) => <MenuItem key={e}>{e}</MenuItem>)}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography sx={{ color: "error.main" }}>
                  Cerrar Sesión
                </Typography>
              </MenuItem>
              <MenuItem>
                <CompanySelector />
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
