import React, { cloneElement, useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import LGCrudToolbar from "./toolbar";
import LGCrudViewMode from "../../molecules/LGCrud/viewMode";
import LGModal from "../../molecules/LGModal";
import { Button, Menu, MenuItem } from "@mui/material";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { esES } from "@mui/x-data-grid/locales";

export default function LGModalCrud({ props }) {
  const {
    fetchFunction,
    deleteFunction,
    downloadFunction,
    data,
    columns,
    addModalData,
    editModalData,
    actions,
    detailData,
    detailMenu,
    customButtons,
  } = props;
  const [rows, setRows] = useState([]);
  const { openSnack } = useSnack();

  // MODAL LOGIC
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ header: "", body: <></> });
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // MENU LOGIC
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDetailClick = (detailMenuItem) => {
    setModalOpen(true);
    setModalData({
      header: detailMenuItem.header,
      body: cloneElement(detailMenuItem.body, {
        props: { id: selectedId, ...props },
      }),
    });
  };

  // FILTER, SORT AND PAGINATION
  const [queryOptions, setQueryOptions] = useState({});
  const [queryParams, setQueryParams] = useState({ ...data });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
    const qp = filterModel.items.reduce((acc, item) => {
      if (item.value !== undefined && item.value !== "") {
        acc[item.field] = item.value;
      }
      return acc;
    }, {});
    setQueryParams({ ...queryParams, ...qp });
  });

  const handlePost = () => {
    setModalOpen(true);
    setModalData({
      header: addModalData.header,
      body: React.cloneElement(addModalData.body, {
        onSubmit: handleFormSubmit,
      }),
    });
  };

  const handleFormSubmit = async () => {
    await fetchData();
    handleModalClose();
  };

  const handleUpdate = (id) => {
    setModalOpen(true);
    setModalData({
      header: editModalData.header,
      body: React.cloneElement(editModalData.body, {
        id,
        onSubmit: handleFormSubmit,
      }),
    });
  };

  const handleFileDownload = (id) => {
    downloadFunction(id);
  };

  const handleShowDetailClick = (event, id) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Seguro quieres eliminar esta entrada?") == true) {
      try {
        await deleteFunction(id);
        setRows(rows.filter((row) => row.id !== id));
        openSnack("Entrada eliminada exitosamente", "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    }
  };

  const fetchData = async () => {
    const res = await fetchFunction(queryParams);
    if (res.data) {
      setRows(res.data);
    }
  };

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...data,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    });
  }, [paginationModel, data]);

  useEffect(() => {
    fetchData();
  }, [queryParams]);

  const newColumns = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 250,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <LGCrudViewMode
            id={id}
            onEdit={actions.includes("update") ? () => handleUpdate(id) : null}
            onDownload={
              actions.includes("download") ? () => handleFileDownload(id) : null
            }
            onDelete={
              actions.includes("delete") ? () => handleDelete(id) : null
            }
            onDetail={
              actions.includes("detail")
                ? (event, id) => handleShowDetailClick(event, id)
                : null
            }
          />,
        ];
      },
    },
  ];

  return (
    <>
      <LGModal
        open={modalOpen}
        handleClose={handleModalClose}
        header={modalData.header}
      >
        {modalData.body}
      </LGModal>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {detailMenu &&
          detailMenu.map((item, index) => (
            <MenuItem key={index} onClick={() => handleOpenDetailClick(item)}>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
      <DataGrid
        rows={rows}
        columns={newColumns}
        slots={{
          toolbar: actions.includes("create") ? LGCrudToolbar : null,
        }}
        slotProps={{
          toolbar: actions.includes("create") 
            ? { 
                handlePost,
                customButtons,
              } 
            : null,
        }}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        getRowHeight={() => "auto"}
        rowCount={-1}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 20, 50]}
        onFilterModelChange={onFilterChange}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </>
  );
}
