import React from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { useCompany } from "../contexts/CompanyContext";
import { useUser } from "../context/UserContext";

const getLogoPath = (companyId) => {
  const companyPrefix = companyId === 1 ? "lg" : companyId === 2 ? "tl" : "el";
  const envPrefix =
    process.env.REACT_APP_ENV === "LOCAL"
      ? "dev"
      : process.env.REACT_APP_ENV === "QA"
      ? "qa"
      : "prod";
  return `/assets/Imgs/logos/${companyPrefix}-${envPrefix}.png`;
};

export default function CompanySelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedCompany, setSelectedCompany, companies } = useCompany();
  const { updateUserData } = useUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanySelect = async (company) => {
    const companyChangeEvent = new CustomEvent("companyChange", {
      detail: {
        oldCompany: selectedCompany,
        newCompany: company,
      },
    });
    window.dispatchEvent(companyChangeEvent);

    localStorage.setItem("selectedCompanyId", company.id);
    setSelectedCompany(company);
    updateUserData();
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color="primary"
        variant="contained"
        sx={{ textTransform: "none" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {selectedCompany?.name || "Seleccionar Compañía"}
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {companies.map((company) => (
          <MenuItem
            key={company.id}
            onClick={() => handleCompanySelect(company)}
            selected={selectedCompany?.id === company.id}
          >
            {company.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
